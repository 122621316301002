<template>
	<v-container>
		<div
			:style="{
				marginBottom: '10px',
				fontWeight: 'bold',
				fontSize: fontsize + 'px',
			}"
		>
			<span 
				:style="{
					marginLeft: '10px',
					fontSize: fontsize + 'px',
				}"
			>
				2021年10月26日（木） 22:13 時点
			</span>
			<span 
				:style="{
					marginLeft: '10px',
					fontSize: fontsize + 'px',
				}"
			>
				最新データ
			</span>
		</div>
		<div
			:style="[
				{
					height: '60px',
					color: 'black',
					'font-size': fontsize + 'px',
					'line-height': '60px',
					'font-weight': 'bold',
					'vertical-align': 'middle',
					'background-color': alertColor(item),
					'margin-bottom': '20px'
				},
			]"
		>
			<span style="margin-left: 20px">{{ item.id }}</span>
			<span style="margin-left: 20px">{{ item.name }}</span>
			<span style="margin-left: 40px; font-size: 14px"
				>常時満水位より
			</span>
			<span style="margin-left: 15px;">+8.21m</span>
		</div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<div class="block-header">回復水位の予測</div>
				<div style="width: 100%; height:400px; background-color: #444">
					<div id="plot" style="height: 400px">
						<GChart
							type="ComboChart"
							:data="chartData"
							:options="chartOptions"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
	computed: {
		item: function () {
			return this.$store.getters.getPlaceById(this.$route.params.id);
		},
		alertColor() {
			return function (item) {
				return this.$store.getters.getAlertColor(item);
			};
		},
		chartData: {
			get() {
				return this.$store.state.waterlevelForecastData[this.item.id];
			},
			set(value) {
				this.$store.state.waterlevelForecastData[this.item.id] = value;
			},
		}
	},
	components: {
		GChart,
	},
	mounted() {
		if (
			!this.item ||
			!this.$route.params.id ||
			!this.$route.params.id.match(/^([0-9]{1,4})/g)
		) {
			this.$router.push({ name: "Home" }).catch(() => {});
		}
		let body = document.getElementsByTagName("body")[0];
		if (body.clientWidth > 1000) {
			this.fontsize = 32;
		} else {
			this.fontsize = 18;
		}
		this.scrollTop();
	},
	created() {
		this.chartData = null;
		this.$store.dispatch("setWaterlevelForecastData", { index: this.item.id });
	},
	methods: {
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	},
	data() {
		return {
			fontsize: 0,
			// Google Chart
			chartOptions: {
				backgroundColor: "#444",
				series: {
					0: { lineWidth: 3, type: "line", lineDashStyle: [6, 6] },
					1: { lineWidth: 2, type: "line", visibleInLegend: false },
					2: { lineWidth: 2, type: "line", visibleInLegend: false },
					3: { lineWidth: 3, type: "line" },
					4: { lineWidth: 3, type: "line" },
					5: { lineWidth: 5, type: "line" },
					6: { lineWidth: 2, type: "line" },
					7: { lineWidth: 1, type: "line", lineDashStyle: [2, 2] },
					8: { lineWidth: 1, type: "line", lineDashStyle: [2, 2] },
				},
				height: "400",
				annotations: {
					textStyle: {
						color: "#ffffff",
						auraColor: "#ffffff",
					},
				},
				explorer: {
					actions: ["dragToZoom", "rightClickToReset"],
					axis: "horizontal",
					keepInBounds: true,
					maxZoomIn: 100.0,
				},
				hAxis: {
					gridlines: {
						count: 8,
						color: "#666",
					},
					textStyle: {
						color: "#ffffff",
					},
					ticks: this.$store.state.waterlevelForecastTickData["tick"]
				},
				vAxis: {
					gridlines: {
						count: 6,
						color: "#666",
					},
					textStyle: {
						color: "#ffffff",
					},
					title: "貯水位",
					titleTextStyle: {
						color: "#ffffff",
					},
				},
				legend: {
					position: "top",
					textStyle: {
						color: "#ffffff",
					},
				},
				chartArea: {
					backgroundColor: {
						stroke: "#666",
						strokeWidth: 1,
					},
					top: "10%",
					left: "8%",
					right: "8%",
					bottom: "20%",
					width: "100%",
					height: "100%",
				},
				colors: ["000000", "000000", "000000", "0000cd", "ff0000", "ffffff", "ffa500", "ffa500", "ffa500"],
			},
		};
	},
};
</script>

<style>
.block-header {
	background-color: #000;
	color: #fff;
	padding: 3px 3px 3px 6px;
	font-size: 12px;
	width: 100%;
	height: 20px;
}
.grid-wrapper {
	display: grid;
	grid-template-columns: 50% 50%;
	background: #666;
	width: 100%;
	margin: auto;
	height: 300px;
	padding-top: 4%;
	padding-left: 10%;
	color: white;
}
</style>