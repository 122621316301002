var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{style:({
			marginBottom: '10px',
			fontWeight: 'bold',
			fontSize: _vm.fontsize + 'px',
		})},[_c('span',{style:({
				marginLeft: '10px',
				fontSize: _vm.fontsize + 'px',
			})},[_vm._v(" 2021年10月26日（木） 22:13 時点 ")]),_c('span',{style:({
				marginLeft: '10px',
				fontSize: _vm.fontsize + 'px',
			})},[_vm._v(" 最新データ ")])]),_c('div',{style:([
			{
				height: '60px',
				color: 'black',
				'font-size': _vm.fontsize + 'px',
				'line-height': '60px',
				'font-weight': 'bold',
				'vertical-align': 'middle',
				'background-color': _vm.alertColor(_vm.item),
				'margin-bottom': '20px'
			} ])},[_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.item.id))]),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.item.name))]),_c('span',{staticStyle:{"margin-left":"40px","font-size":"14px"}},[_vm._v("常時満水位より ")]),_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v("+8.21m")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('div',{staticClass:"block-header"},[_vm._v("回復水位の予測")]),_c('div',{staticStyle:{"width":"100%","height":"400px","background-color":"#444"}},[_c('div',{staticStyle:{"height":"400px"},attrs:{"id":"plot"}},[_c('GChart',{attrs:{"type":"ComboChart","data":_vm.chartData,"options":_vm.chartOptions}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }